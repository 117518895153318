import React from "react";
import { osName, OsTypes } from "react-device-detect";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import useLocalStorage from "~/utils/hooks/use-local-storage";

const UserPreferenceContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useUserPreferenceContextVals = (defaults) => {
  const [open, setOpen] = React.useState(false);
  const [settings, setSettings] = React.useState({ theme: osName === OsTypes.IOS ? "ios" : "material", ...defaults.settings });
  const [recipeExpanded, setRecipeExpanded] = React.useState(false);
  const [userSortBy, setUserSortBy] = useLocalStorage("userSortBy", "name");
  const [userSortOrder, setUserSortOrder] = useLocalStorage("userOrderBy", "asc");

  const openUserPreference = () => {
    setOpen(true);
  };

  const closeUserPreference = () => {
    setOpen(false);
  };

  const toggleRecipeExpanded = () => {
    setRecipeExpanded(!recipeExpanded);
  };

  return {
    open,
    setOpen,
    openUserPreference,
    closeUserPreference,
    settings,
    setSettings,
    toggleRecipeExpanded,
    recipeExpanded,
    userSortBy,
    setUserSortBy,
    userSortOrder,
    setUserSortOrder,
  };
};

const UserPreferenceContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const userpreferenceContext = useUserPreferenceContextVals(props.defaults);

  return <UserPreferenceContext.Provider value={{ ...userpreferenceContext }}>{props.children}</UserPreferenceContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useUserPreferenceContext = () => useContext(UserPreferenceContext);
const useUserPreferenceSelectorContext = (selector: any) => {
  return useContextSelector(UserPreferenceContext, selector);
};
export { UserPreferenceContextProvider, useUserPreferenceContext, useUserPreferenceSelectorContext };
